.adventure-info {
	margin: 0;
	flex-wrap: wrap;
	width: 38rem;
	max-width: 100vw;
	gap: 1rem;
}

.main-adventure-content.flex-box {
	align-items: flex-start;
	flex-direction: column;
}

.adventure-tick-panel.flex-box {
	margin: 0 10px;
	padding-top: 20px;
	min-width: 400px;
	flex-direction: column;
	align-items: flex-start;
}

.adventure-gallery {
	width: 100%;
	height: 200px;
	background-color: #eee;
	display: block;
}

.adventure-bio.flex-box {
	align-self: flex-start;
	padding: 0.5rem 0 2rem;
}

.adventure-viewer {
	flex-direction: column;
	width: 38rem;
	padding: 0 1rem;
}

.user-image {
	width: 2rem;
	height: 2rem;
	background-color: #ddd;
	border-radius: 2rem;
	overflow: hidden;
	box-shadow: 0 0.1rem 0.1rem var(--box-shadow-opacity);
}

.user-image img {
	width: 100%;
	height: 100%;
}

.mail-icon {
	width: 25px;
	height: 15px;
	background-color: #ddd;
}

.header-subtext {
	font-size: 0.85rem;
}

.new-adventure-brief {
	width: auto;
	max-width: 30rem;
	line-height: 1.5rem;
}

.adventure-display-grid {
	min-width: 30rem;
}

.import-adventures-button input {
	display: none;
}

.edit-add-path {
	justify-content: space-between;
	margin-right: 0rem;
}

.path-buttons {
	flex-direction: column;
	gap: 0.5rem;
	align-items: flex-end;
	font-size: 0.9rem;
}

.complete-adventure-box .button-container {
	margin-top: 2rem;
	gap: 0.5rem;
	flex-direction: row-reverse;
	justify-content: flex-start;
}

.adventure-page-action-items {
	justify-content: flex-start;
	gap: 0.5rem;
}

.create-new-button {
	gap: 1rem;
	justify-content: flex-end;
}
