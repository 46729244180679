.menu {
	position: relative;
}

.menu-buttons {
	position: fixed;
	background-color: var(--main-light);
	box-shadow: 0 0 10px var(--box-shadow-opacity);
	border-radius: 0 0.7rem 0.7rem 0;
	color: var(--primary-accent-color);
	font-size: 1rem;
	overflow: hidden;
	max-height: 1000px;
	transition: all 0.3s ease-in-out;
	z-index: 1;
	pointer-events: all;
}

.drop-list-subtext {
	color: var(--primary-accent-color);
}

.drop-list-image {
	padding-top: 0.2rem;
}

.drop-list-secondary {
	font-size: 0.9rem;
}

.menu-button.flex-box {
	transition: all 0.3s ease-in-out;
	cursor: pointer;
	color: inherit;
	width: 100%;
	margin: 0;
	background-color: transparent;
	border-radius: 0;
	justify-content: flex-start;
	padding: 0 1rem;
	height: 3rem;
}

.menu-buttons .menu-button:hover {
	background-color: var(--main-off-white);
}

.menu-opener {
	background: transparent;
	color: var(--primary-accent-color);
	height: auto;
	margin: 0;
	padding: 0 0.5rem;
}

.menu-opener:hover {
	background-color: var(--main-off-white);
}
