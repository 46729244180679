button {
	font-family: inherit;
}

.button {
	background-color: var(--primary-accent-color);
	color: var(--main-off-white);
	border: none;
	border-radius: 0.3rem;
	height: 2.2rem;
	font-weight: 500;
	font-size: 1rem;
	margin: 0;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	text-decoration: none;
	padding: 0 1.5rem;
	text-decoration: none;
	justify-content: center;
}

a {
	text-decoration: none;
}

.button svg {
	flex: 1;
}

.button:hover {
	background-color: var(--primary-accent-color-hover);
}

.button:disabled {
	background-color: var(--primary-disabled-color);
}

.link.flex-box,
.link:hover.flex-box,
.link,
.link:hover {
	background-color: transparent;
	padding: 0;
	color: -webkit-link;
	display: inline;
	font-size: inherit;
}

.secondary-button.flex-box,
.secondary-button:hover.flex-box,
.header-button.flex-box,
.header-button:hover.flex-box {
	padding: 0 1.5rem;
	background-color: var(--main-light);
	box-shadow: none;
	color: var(--primary-accent-color);
	font-weight: 600;
	display: inline-flex;
	font-size: 1rem;
}

.secondary-button.flex-box {
	background-color: transparent;
}

.secondary-button:hover.flex-box {
	background-color: var(--main-off-white);
}

.logo-button.flex-box,
.logo-button:hover.flex-box {
	padding: 0;
}

.header-button:hover {
	background-color: var(--main-off-white);
}

.button.small {
	font-size: 0.9rem;
	padding: 0.3rem 0.8rem;
}

.button.small:hover {
	font-size: 0.9rem;
	padding: 0.3rem 0.8rem;
}

.delete-button.flex-box {
	color: var(--alert-error-color);
	background-color: var(--main-light);
}

.delete-button.flex-box:hover {
	background-color: var(--main-off-white);
	color: var(--alert-error-color);
}

.about-hero-button {
	background-color: var(--primary-accent-color);
	font-size: 25px;
	height: 60px;
	border-radius: 5px;
	font-weight: normal;
	margin-right: 15px;
	margin-top: 15px;
}

.button-bar-button {
	width: auto;
	border-radius: 0.8rem;
	border: none;
	outline: none;
	box-shadow: 0 5px 10px var(--box-shadow-opacity);
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	margin: 0;
}

.adventure-pill-selector .button {
	background-color: var(--main-light);
	color: var(--main-dark);
	gap: 0.8rem;
	height: 3rem;
	padding-left: 1rem;
}

.adventure-pill-selector .button:hover {
	background-color: var(--main-off-white);
}

.adventure-pill-selector .button.current-adventure-type {
	background-color: var(--primary-accent-color);
	color: var(--main-light);
}

.adventure-pill-selector .button.current-adventure-type:hover {
	background-color: var(--primary-accent-color-hover);
}

.select-many-button.flex-box {
	padding: 0.5rem;
	border: 0.1rem solid var(--border-color);
	min-width: 8rem;
}

.select-many-button.flex-box span {
	font-weight: 400;
	max-width: 20rem;
	white-space: nowrap;
	overflow: hidden;
	justify-content: flex-start;
	padding: 0;
	text-overflow: ellipsis;
}

.adventure-result {
	gap: 0.5rem;
	color: inherit;
	background-color: transparent;
	padding: 0;
	font-size: 0.9rem;
}

.adventure-result:hover {
	background-color: transparent;
}

.icon-flipped {
	transform: rotate(180deg);
}
