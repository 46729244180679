.button-bar .regular-buttons {
	gap: 0.5rem;
}

.button-bar-button:hover {
	background-color: var(--main-off-white);
	color: var(--main-dark);
}

.button-bar {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	padding: 10px;
	background: linear-gradient(#00000099, transparent);
	align-items: center;
}

@media screen and (max-width: 500px) {
	.button-bar {
		padding: 1rem;
		padding-bottom: 0.5rem;
	}
}
