.login-cta {
	width: 200px;
	margin: 5px 0;
	justify-content: center;
}

.forgot-button {
	padding: 0;
	font-size: 1rem;
}

.create-account-cta {
	margin: 0;
	font-size: 0.9rem;
	text-align: center;
}

.new-account-button.button,
.new-account-button:hover.button {
	font-size: inherit;
	padding: 0.5rem;
	margin: 0.5rem;
	width: auto;
	display: inline-flex;
}

.signup-form.flex-box,
.login-form.flex-box {
	margin: 0;
	align-items: center;
	flex-wrap: nowrap;
	flex-direction: column;
	gap: 0;
}

.login-form {
	margin-bottom: 180px;
}

.main-login-content,
.main-signup-content {
	max-width: 100%;
	height: auto;
}

.signup-buttons.flex-box {
	box-shadow: none;
	flex-direction: column;
	align-items: center;
}

.signup-buttons .cta-button {
	width: 350px;
	justify-content: center;
}

.signup-header.flex-box {
	align-items: center;
	min-height: 80px;
}

.signup-header .field {
	align-items: center;
}

.reset-form {
	align-items: center;
	flex-direction: column;
}

.desc {
	font-weight: 500;
	margin: 0;
}

.login-card {
	max-width: 12rem;
}
