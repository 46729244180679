input {
	font-family: inherit;
}

.form-field-static {
	font-size: 18px;
	margin-bottom: 10px;
}

.label-field {
	font-size: 0.85rem;
	font-weight: 400;
	margin: 0 0.3rem 0.3rem;
	display: block;
	color: var(--main-off-dark);
}

.form-field.text,
.form-field.text-area,
.form-field.select,
.form-field.tel,
.form-field.email {
	background-color: var(--main-off-white);
	border: none;
	border-radius: 0.3rem;
	font-size: 0.9rem;
	margin: 0;
	display: block;
	/* subtract the padding from the form field and the border*/
	width: 100%;
	padding: 0.5rem 0.8rem;
	/* transition: all 0.15s ease-in-out; */
	outline: none;
}

.form-field.text:focus,
.form-field.text-area:focus,
.form-field.select:focus,
.form-field.tel:focus,
.form-field.email:focus {
	border: 1px solid var(--primary-accent-color);
	padding: calc(0.5rem - 1px) calc(0.8rem - 1px);
}

.form-field.select {
	width: 100%;
	min-width: 200px;
}

.form-field.header-input {
	font-size: 1.1rem;
}

.form-field.password {
	background-color: var(--main-off-white);
	border: none;
	border-radius: 10px;
	font-size: 1rem;
	margin: 0;
	width: 100%;
	height: 2.1rem;
	padding: 0 16px;
	transition: all 0.2s ease-in-out;
	justify-content: space-between;
}

.password-input-field {
	font-size: inherit;
	background-color: inherit;
}

.password .password-switch-field {
	font-size: inherit;
}

.form-field.password.focus {
	border: 0.1rem solid var(--primary-accent-color);
}

.form-field.password input {
	outline: none;
	position: relative;
	height: 100%;
	padding: 0;
	border: 0;
	flex: 1;
	font-size: 0.9rem;
}

.form-field.password button {
	background-color: transparent;
	color: var(--main-dark);
	padding: 0;
}

.form-field.form-field.password.focus button {
	color: var(--primary-accent-color);
}

.form-field-static.card-header {
	font-size: 25px;
	margin: 10px;
	font-weight: 500;
}

.form-field.card-header {
	font-size: 1rem;
	margin: 0.8rem;
	display: block;
	max-width: calc(100% - 1.6rem);
}

.label-field.checkbox {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
	font-weight: 500;
	font-size: 14px;
	flex-direction: row;
	padding: 0 0.5rem;
	gap: 0.5rem;
}

.label-field.checkbox span {
	font-size: 0.9rem;
}

.hidden-checkbox {
	display: none;
}

.checkbox .checkbox-illus,
.select-many .select-many-illus {
	width: 1.4rem;
	height: 1.4rem;
	border: 2px solid var(--border-color);
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hidden-checkbox:checked + .checkbox-illus,
.hidden-checkbox:checked + .select-many-illus {
	background-color: var(--primary-accent-color);
	border: 2px solid var(--main-light);
	outline: none;
}

.select-many-option {
	margin-bottom: 0.5rem;
	cursor: pointer;
	flex-direction: row;
	gap: 0.5rem;
}

.form-field.select-many {
	position: relative;
}

.select-many-options-selector.flex-box {
	flex-direction: column;
	align-items: flex-start;
	max-height: 15rem;
	flex-wrap: wrap;
	align-items: flex-start;
	border: 0.1rem solid var(--border-color);
	border-radius: 0.5rem;
	padding: 0.8rem;
	position: absolute;
	top: 0;
	left: 0;
	background-color: var(--main-light);
	z-index: 1;
	box-shadow: 0 0.5rem 0.8rem var(--box-shadow-opacity);
	gap: 0.1rem;
}

.slider {
	width: 100%;
}

.form-field.text-area {
	height: 100px;
	font-family: inherit;
	padding-top: 8px;
	padding-bottom: 8px;
	resize: none;
}

.multi-field.flex-box {
	flex-direction: column;
	align-items: flex-start;
}

.multi-field .form-field {
	margin-top: 0;
}

.multi-field-box {
	width: 100%;
	gap: 1rem;
}
