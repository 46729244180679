.alert {
	position: fixed;
	bottom: 2rem;
	right: 3rem;
	min-width: 0rem;
	min-height: 75px;
	background-color: var(--primary-accent-color);
	border-radius: 15px;
	padding: 15px;
	box-shadow: 0 5px 10px var(--box-shadow-opacity);
	color: var(--main-light);
	font-size: 1.1rem;
	opacity: 0;
	transition: all 0.5s ease-in-out;
	display: none;
	line-height: 1.6em;
}

.alert.show-alert {
	opacity: 100;
	display: block;
	min-width: 20rem;
}

.alert.fade-alert {
	display: block;
}
