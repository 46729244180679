.messaging-content {
	width: 50rem;
	max-width: 100%;
	padding: 0;
	gap: 1rem;
	max-height: 80vh;
}

.message-bar {
	gap: 1rem;
	background-color: var(--main-light);
}

.send-message-button,
.message-bar .form-field,
.message-bar label {
	margin: 0;
}

.messages-content {
	flex: 1;
	width: 100%;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	overflow-y: auto;
}

.chat-content {
	min-width: 22rem;
	align-items: flex-end;
	flex-direction: column;
	gap: 1rem;
}

.friend-search-list li.current-conversation {
	background-color: var(--primary-accent-color);
	color: var(--main-off-white);
	position: relative;
}

.friend-search-list li.current-conversation:hover {
	color: var(--main-dark);
}

.friend-search-list li span:nth-child(2) {
	font-size: 0.8rem;
	color: var(--main-off-dark);
	font-weight: 400;
	width: 100%;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.friend-search-list li.current-conversation span:nth-child(2) {
	color: var(--main-off-white);
	transition: all 0.3s ease-in-out;
}

.friend-search-list li.current-conversation:hover span:nth-child(2) {
	color: var(--main-off-dark);
}

.friend-search-list {
	list-style-type: none;
	padding: 0;
	margin: 1rem 0;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 0.2rem;
}

.notification-button {
	width: 0.8rem;
	height: 0.8rem;
	border-radius: 1rem;
	background-color: var(--primary-accent-color);
	position: absolute;
	top: 0.5rem;
	right: 0.5rem;
}

.current-conversation .notification-button {
	background-color: var(--main-light);
}

.friend-search-list li {
	position: relative;
	font-weight: 500;
	font-size: 0.9rem;
	padding: 0 0.5rem;
	height: 3.5rem;
	margin: 0;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	background-color: var(--main-light);
	border-radius: 0.5rem;
	align-items: center;
	justify-content: flex-start;
	gap: 0.8rem;
	width: 100%;
	/* border-bottom: 0.1rem solid var(--border-color); */
}

.friend-search-list li:hover {
	background-color: var(--box-shadow-opacity);
}

.friend-search-list li img {
	height: 2.2rem;
	width: 2.2rem;
	border-radius: 0.2rem;
}

.conversation-selector {
	width: 16rem;
}

.conversation-name {
	text-overflow: ellipsis;
	max-width: 10rem;
	white-space: nowrap;
	overflow: hidden;
}

.last-message {
	text-overflow: ellipsis;
	max-width: 10rem;
}

.conversation-messages {
	margin: 0;
	padding: 0;
	flex-direction: column-reverse;
	gap: 0.3rem;
}

.conversation-messages li {
	width: 100%;
	justify-content: flex-start;
}

.conversation-messages li.me {
	justify-content: flex-end;
}

.conversation-messages li .message-text {
	padding: 0.4rem 0.6rem;
	border-radius: 0.8rem 0.8rem 0.8rem 0;
	background-color: var(--border-color);
	max-width: 60%;
	word-wrap: break-word;
	font-size: 0.85rem;
	white-space: pre-line;
}

.message-text a {
	color: var(--default-text-color);
	text-decoration: underline;
}

.conversation-messages li.me .message-text {
	background-color: var(--primary-accent-color);
	color: var(--main-off-white);
	border-radius: 0.8rem 0.8rem 0rem 0.8rem;
}

.send-message-button.small {
	background-color: var(--primary-accent-color);
	padding: 0 1rem;
	height: 2.1rem;
}

.messaging-container {
	align-items: stretch;
	gap: 0.8rem;
	min-height: 40vh;
}

.conversation-card {
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.2rem;
}

.messaging-display-card .display-content {
	max-width: 50rem;
	width: 100vw;
}

.conversation-icon {
	width: 3rem !important;
	height: 3rem !important;
	border-radius: 3rem !important;
}

.multi-image-container {
	background-color: var(--main-off-white) !important;
	flex-shrink: 0;
	position: relative;
}

.multi-image-icon {
	width: 1.2rem !important;
	height: 1.2rem !important;
	border-radius: 1.2rem !important;
	position: absolute !important;
}
