.error-field {
	background-color: var(--alert-error-color);
	color: var(--main-off-white);
	width: 100%;
	padding: 1rem 0.8rem;
	margin: 0;
	transition: all 0.2s ease-in-out;
	min-height: 3rem;
	border-radius: 0.5rem;
	/* max-width: 26.4rem; 28rem - 0.8rem of padding on either side */
}

.error-field.collapsed {
	background-color: transparent;
	height: 0;
}
