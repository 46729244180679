.map-container {
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
}

.mapboxgl-canvas-container {
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 500px) {
	.card-open {
		display: none;
	}
}
