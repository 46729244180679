.display-card {
	margin: 10px;
	min-width: 40rem;
	border-radius: 0.5rem;
	box-shadow: 0 0 10px var(--box-shadow-opacity);
	flex-direction: column;
	overflow: hidden;
	transition: opacity 0.3s ease-in-out;
	pointer-events: auto;
	position: relative;
	background-color: var(--main-light);
	z-index: 1;
}

.display-card-container.flex-box {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	align-items: flex-start;
	pointer-events: none;
}

.display-card-container.block-background {
	pointer-events: all;
	background-color: var(--main-off-dark-opacity);
}

.display-card-container.left {
	justify-content: flex-start;
}

.display-card-container.open .display-card {
	opacity: 1;
}

.display-card-container.center {
	justify-content: center;
	align-items: center;
}

.display-header.flex-box {
	width: 100%;
	left: 0;
	display: flex;
	background-color: var(--main-light);
	z-index: 2;
	color: var(--primary-accent-color);
	font-size: 2rem;
	font-weight: 500;
	padding: 0 0.6rem 0 0.4rem;
}

.display-card-header-contents {
	margin: 0;
	padding: 1rem 0;
	font-weight: inherit;
	font-size: inherit;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 26rem;
	overflow: hidden;
}

.display-header-spacer {
	flex: 1;
}

.display-back-button {
	border: none;
	color: var(--primary-accent-color);
	padding: 1rem 0.8rem;
	outline: none;
	background-color: transparent;
	cursor: pointer;
	transition: all 0.2s ease-in-out;
	margin: 0;
	margin-right: 0.5rem;
}

.display-back-button svg {
	transition: all 0.2s ease-in-out;
	color: inherit;
	flex: none;
}

.display-back-button:hover {
	background-color: #eee2;
}

.display-content {
	flex: 1;
	display: grid;
	overflow-y: auto;
	transition: all 0.3s ease-in-out;
	max-height: 89vh;
	max-width: 40rem;
	padding: 1.6rem 1rem 1rem;
	gap: 1.2rem;
}

.profile-content {
	background-color: var(--main-light);
	width: 100%;
	position: relative;
	height: 80vh;
	overflow-y: auto;
}

.image-header.flex-box {
	flex-direction: row;
	gap: 0.8rem;
}

.header-contents.flex-box {
	align-items: center;
	flex: 1;
	padding: 0 0.8em 0 0;
}

.action-buttons.flex-box {
	flex-direction: column;
	align-items: stretch;
	gap: 0.5rem;
}

.action-buttons.flex-box.row {
	flex-direction: row;
	justify-content: flex-end;
	align-items: flex-end;
}

.tick-list {
	width: 100%;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.tick-list.flex-box {
	flex-direction: column;
	font-size: 1rem;
	align-items: flex-start;
	justify-content: flex-start;
	max-height: 14rem;
	overflow-y: auto;
}

.tick-list-header {
	margin: 0;
	font-weight: 600;
	font-size: 1rem;
}

.tick.flex-box {
	padding: 0.3rem 0.5rem;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	width: 100%;
	border-bottom: 1px solid var(--border-color);
	border-left: none;
	justify-content: space-between;
	font-size: 0.9rem;
}

.main-text {
	max-width: 12rem;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.tick.multi-field-tick {
	justify-content: space-between;
	padding: 1rem 0;
}

.tick:hover {
	background-color: var(--main-off-white);
}

.user-profile-display-card .display-content {
	max-width: 40rem;
}

@media screen and (max-width: 500px) {
	.display-card-container .display-card {
		height: 100vh;
		min-width: 100vw;
		margin: 0;
		border-radius: 0;
		width: 100vw;
	}

	.profile-content {
		height: 100%;
	}
}
