/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap'); */
/* @import url("https://use.typekit.net/yeo7sxx.css"); */
@import './variables.css';

html {
	font-family: 'Helvetica Neue', Arial, sans-serif;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	overflow: auto;
	font-size: 14px;
}

body {
	margin: 0;
}

.full-width {
	width: 100%;
}

.flex-spacer {
	flex: 1;
}

.flex-box {
	display: flex;
	justify-content: center;
	align-items: center;
}

.content-container {
	max-width: 50rem;
	width: 100%;
	padding: 0 2rem;
	margin: auto;
}

.content-container p {
	line-height: 1.4em;
}

* {
	box-sizing: border-box;
}

.divider {
	padding-bottom: 0.5rem;
	margin-bottom: 0.5rem;
	border-bottom: 0.1rem solid var(--border-color);
}

.no-padding {
	padding: 0 !important;
}
