.marketing-content-container h1 {
	padding: 0 2rem;
	font-size: 2rem;
	margin: 0;
	color: var(--primary-accent-color);
}

.marketing-content-container {
	justify-content: flex-start;
	overflow: auto;
	align-items: center;
}

.marketing-content-container > article {
	max-width: 640px;
}

.privacy-page {
	padding: 0 1rem 20vh;
}

.marketing-content-container .updated-date {
	color: var(--main-off-dark);
	font-size: 0.9rem;
	padding: 0 2rem;
}

.marketing-content-container .header-block {
	margin: 5rem 0;
}

.marketing-content-container section {
	margin: 3rem 2rem;
}

hr {
	border-top: 0.1rem solid var(--border-color);
}

#support-form {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: stretch;
}

label {
	display: flex;
	flex-direction: column;
	gap: 0.2em;
}

label span {
	font-size: 0.8em;
}

label input,
label textarea {
	font-size: 1rem;
	padding: 0.5rem 1rem;
	border-radius: 0.5em;
	border: 1px solid var(--border-color);
}

label textarea {
	font-family: inherit;
	resize: none;
	height: 8em;
	padding: 0.5rem;
}

#support-submit-button {
	background-color: var(--primary-accent-color);
	color: var(--main-light);
	border: none;
	border-radius: 0.5em;
	padding: 0.5em;
	font-size: 1.1rem;
	font-weight: 600;
}

#support-page .header-block {
	margin-bottom: 2rem;
}

#form-error {
	border: 0.1rem solid var(--alert-error-color);
	border-radius: 0.5rem;
	padding: 0.5rem 1rem;
	margin: 3rem 2rem;
	background-color: #fadede;
}

#form-success {
	border: 0.1rem solid var(--primary-accent-color);
	border-radius: 0.5rem;
	padding: 0.5rem 1rem;
	margin: 3rem 2rem;
	background-color: #dbedea;
}
