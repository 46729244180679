.mobile-page {
	flex-direction: column;
	height: 100vh;
}

.mobile-page > div {
	font-size: 1.5rem;
	text-align: center;
	margin: 1.5rem 1.5rem 0;
}

.mobile-page > div > p {
	font-size: 1rem;
}

.app-store-button {
	background-color: transparent;
	padding: 0;
	margin: 1rem 0;
}
