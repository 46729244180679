.aspect-icon.north {
	transform: rotate(-90deg);
}

.aspect-icon.north-east {
	transform: rotate(-45deg);
}

.aspect-icon.east {
	transform: rotate(0deg);
}

.aspect-icon.south-east {
	transform: rotate(45deg);
}

.aspect-icon.south {
	transform: rotate(90deg);
}

.aspect-icon.south-west {
	transform: rotate(135deg);
}

.aspect-icon.west {
	transform: rotate(180deg);
}

.aspect-icon.north-west {
	transform: rotate(-135deg);
}

.logo-inline {
	text-shadow: 0 5px 10px var(--box-shadow-opacity);
}

.pin-marker {
	background-color: var(--main-light);
	border-radius: 100%;
	padding: 0.15rem;
	border: 1px solid var(--main-dark);
}
