.marketing-navigation-container {
	width: 300px;
	height: 100vh;
	position: fixed;
	top: 0;
	left: -350px;
	background-color: var(--main-light);
	transition: all 0.3s ease-in-out;
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 1rem var(--box-shadow-opacity);
}

.marketing-nav-open {
	left: 0;
}

.marketing-nav-header {
	width: 100%;
	height: 4rem;
	border-bottom: 0.1rem solid var(--border-color);
	display: flex;
	align-items: center;
	padding: 0 1rem;
	justify-content: flex-end;
}

.marketing-navigation-container ul {
	padding: 0.5rem;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.marketing-nav-open li {
	list-style-type: none;
}

.marketing-navigation-container li a {
	text-decoration: none;
	padding: 1rem 2rem;
	font-weight: 600;
	font-size: 1rem;
	color: var(--primary-accent-color);
	transition: all 0.3s ease-in-out;
	display: flex;
	width: 100%;
	border: none;
	background-color: transparent;
	border-radius: 0.5rem;
	color: var(--main-off-dark);
}

.marketing-navigation-container li a:hover {
	background-color: var(--primary-accent-color);
	color: var(--main-light);
}
