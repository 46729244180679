.accordion {
	width: 100%;
	border: 1px solid var(--border-color);
	border-radius: 10px;
	overflow: hidden;
	margin: 1rem 0.8rem;
}

.accordion-top {
	background-color: var(--main-off-white);
	padding: 5px 15px;
	margin: 0;
	font-size: 18px;
	font-weight: 700;
	cursor: pointer;
}

.accordion-content-wrapper {
	transition: all 0.3s ease-in-out;
	margin-top: 15px;
	padding: 0 15px;
	max-height: 500px;
}

.accordion-closed .accordion-content-wrapper {
	max-height: 0;
	margin: 0;
}

.accordion-expander {
	transform: rotate(270deg);
	transition: all 0.3s ease-in-out;
}

.accordion-closed .accordion-expander {
	transform: rotate(90deg);
}

@media screen and (max-width: 500px) {
	.accordion {
		margin: 0.8rem;
		margin-bottom: 1.3rem;
	}

	.accordion-content-wrapper {
		padding: 0;
	}
}
