h1 {
	margin: 0;
	padding: 10px 12px;
	font-size: 25px;
	font-weight: 500;
}

.profile-photo {
	height: 100%;
	width: 80px;
	background-color: #eee;
}

.scroller-container.flex-box {
	height: 13rem;
	gap: 0.8rem;
	width: 100%;
	overflow-x: scroll;
	overflow-y: hidden;
	justify-content: flex-start;
	padding-bottom: 1rem;
}

.scroller-container img {
	height: 100%;
	cursor: pointer;
}

.file-upload-container.flex-box {
	border: 0.1rem dashed var(--border-color);
	height: 100%;
	margin: 0;
	cursor: pointer;
	width: 12rem;
	min-width: 12rem;
	justify-content: center;
	border-radius: 0.5rem;
	transition: all 0.3 ease-in-out;
}

.file-upload-container.flex-box:hover {
	border: 0.1rem solid var(--primary-accent-color-hover);
}

.file-upload-container .image-input {
	display: none;
}

.stats.flex-box {
	align-items: flex-start;
	margin: 0;
	gap: 1rem;
}

.stat-field {
	font-size: 2rem;
	margin: 0;
	color: var(--primary-accent-color);
}

.stat-template.flex-box {
	flex: none;
	min-width: 100px;
	cursor: pointer;
	padding: 0.8rem;
	border-radius: 0.5rem;
	transition: all 0.3s ease-in-out;
	margin: 0;
}

.stat-template.selected {
	background-color: var(--main-off-white);
}

.stat-template.flex-box:hover {
	background-color: var(--main-off-white);
}

.profile-photo-handlers {
	position: relative;
	gap: 1rem;
}

.stat-field.form-field-static {
	font-size: 25px;
}

.location-field.form-field-static {
	font-size: 12px;
	min-width: 150px;
	align-self: flex-start;
}

.location-field.label-field {
	align-self: flex-start;
}

.user-adventure-viewer.flex-box {
	align-items: flex-start;
	gap: 1rem;
	min-width: 30rem;
}

.friend-list-container.flex-box,
.tick-list-container.flex-box {
	padding: 0;
	flex-direction: column;
	align-items: flex-start;
	flex: 1;
	width: 100%;
	min-width: 18rem;
	gap: 0.5rem;
}

.friend-list-container.flex-box {
	gap: 1rem;
	min-width: 20rem;
}

.main-user-content {
	width: 40rem;
}

.profile-image-uploader.flex-box {
	flex-direction: column;
	align-items: flex-start;
}

.user-profile-header.flex-box {
	min-height: 6rem;
}

.profile-image-upload.file-upload-container.flex-box,
.profile-picture,
.image-input-background {
	width: 8rem;
	height: 8rem;
	background-color: var(--border-color);
	border-radius: 100%;
	cursor: pointer;
	margin: 0;
	min-width: 0;
	border: none;
}

.overlay-upload-button.flex-box {
	border: none;
	height: 9rem;
	width: 9rem;
	min-width: 9rem;
	left: 0;
	position: absolute;
	margin: 0;
}

.upload-photo-button-container.flex-box {
	flex-direction: column;
	align-items: flex-start;
}

.friends-search {
	width: 300px;
	max-width: 100%;
}

.friend-list.flex-box {
	margin-top: 0;
}

.user-bio-content {
	gap: 2rem;
	justify-content: flex-start;
	margin: 2rem 1rem 1rem;
}

.user-bio-text {
	flex-direction: column;
	align-items: flex-start;
	gap: 1rem;
}

.user-bio {
	color: var(--main-off-dark);
}

.friend-image {
	width: 2rem;
	height: 2rem;
	border-radius: 2rem;
}

.tick.drop-list-item {
	justify-content: flex-start;
	align-items: center;
	gap: 1rem;
}

.drop-list-item {
	border-radius: 0.3rem;
}
