.field {
	font-size: 0.95rem;
	line-height: 1.2rem;
	white-space: nowrap;
	max-width: 100%;
}

.field-page.flex-box {
	width: 100%;
	flex-direction: column;
	justify-content: flex-start;
}

.field.flex-box {
	flex-direction: column;
	align-items: flex-start;
	flex: 1;
	padding: 0 0.8rem;
	gap: 0.5rem;
}

.field-row.flex-box {
	width: 100%;
	padding: 1rem 0;
	justify-content: flex-start;
	align-items: flex-start;
}

.field-row.border-bottom {
	border-bottom: 0.1rem solid var(--border-color);
}

.field.border-right {
	border-right: 0.1rem solid var(--border-color);
}

.field-header {
	font-size: 1.1rem;
	margin: 0;
	font-weight: 500;
}

.field-image {
	height: 6rem;
	width: 6rem;
}

.field.flex-box.wide {
	padding: 0;
}

.min-field.flex-box {
	flex: none;
}

.long-text.field {
	white-space: pre-line;
}

.field-image img {
	width: 100%;
	height: 100%;
}

.field-value.flex-box {
	justify-content: flex-start;
	gap: 0.5rem;
	max-width: 100%;
}

.view-location.flex-box {
	justify-content: flex-start;
	align-items: center;
	gap: 0.5rem;
	flex-direction: row;
	padding: 0;
}

.location-row.flex-box {
	padding: 0.5rem 0;
}

.narrow-field.flex-box {
	padding: 0.5rem 0;
}

.large-header.field-header {
	font-size: 1.4rem;
	margin-bottom: 0.5rem;
}

.left-header.field-header {
	align-self: flex-start;
	color: var(--main-off-dark);
}

.card-field.field {
	background: var(--primary-accent-light);
	padding: 1rem;
	border-radius: 0.8rem;
}
