.image-viewer-background.flex-box {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	background-color: #000000aa;
	justify-content: stretch;
	z-index: 2;
	backdrop-filter: blur(5px);
	flex-direction: column;
	padding-bottom: 1rem;
}

.image-view {
	max-height: calc(100vh - 4.5rem);
}

.image-viewer-header {
	width: 100%;
	height: 4.5rem;
	background: linear-gradient(#00000099, transparent);
}

.image-carousel.flex-box {
	justify-content: space-between;
	align-self: stretch;
	flex: 1;
}
